<template>
    <div class="grid">
        <Toast />
        <div class="col-12">
            <div class="card">
                <h5>Tahun Akademik</h5>
                <div class="grid p-fluid">
                    <div class="col-12 sm:col-2">
                        <div class="add"><Button type="button" icon="pi pi-plus" label="Tambah" class="p-button mb-2 mt-2 ml-2" @click="modalInput = true" /></div>
                    </div>
                </div>
                <DataTable
                    @page="pages($event)"
                    :value="tahun_akademik"
                    :paginator="true"
                    class="p-datatable-gridlines"
                    :rows="perPage"
                    v-model:filters="filters1"
                    dataKey="id"
                    :rowHover="true"
                    filterDisplay="menu"
                    :loading="loading1"
                    :filters="filters1"
                    responsiveLayout="scroll"
                    :globalFilterFields="field"
                >
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div class="add"><Dropdown :options="perPages" id="Role" required="true" autofocus v-model.lazy="perPage" /> Per Halaman dari {{ total_tahun_akademik }} data</div>
                            <div class="filter">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()" />
                                <span class="p-input-icon-left mb-2">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters1.global.value" placeholder="Keyword Search" style="width: 100%" />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty> Tidak ada data. </template>
                    <template #loading> Loading data. mohon tunggu.... </template>
                    <Column field="no" header="No" style="width: 50px">
                        <template #body="{ data }">
                            {{ data.no }}
                        </template>
                    </Column>
                    <Column field="tahun_ak" header="Tahun Akademik" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.tahun_ak }}
                        </template>
                    </Column>
                    <Column field="dibuat" header="Created At" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.dibuat }}
                        </template>
                    </Column>
                    <Column field="diubah" header="Updated At" style="min-width: 12rem" :sortable="true">
                        <template #body="{ data }">
                            {{ data.diubah }}
                        </template>
                    </Column>
                    <Column field="actions" header="Actions" bodyClass="text-center" style="min-width: 2rem">
                        <template #body="{ data }">
                            <div class="flex sm:flex-row">
                                <div class="mt-2">
                                    <Button type="button" icon="pi pi-pencil" label="" class="p-button-warning mt-2 ml-2" @click="editData(data)" />
                                </div>
                                <!-- <div class="mt-2">
                                    <Button type="button" icon="pi pi-trash" label="" class="p-button-danger mt-2 ml-2" @click="deleteData(data)" />
                                </div> -->
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <!-- MODAL INPUT -->
            <Dialog header="Tambah Data Tahun Akademik" v-model:visible="modalInput" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="register()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="data.tahun_ak" @blur="v$.data.tahun_ak.$touch()" :class="{ 'p-invalid': v$.data.tahun_ak.$invalid ? !v$.data.tahun_ak.$anyError : null }" />
                        <label for="name">Nama Tahun Akademik</label>
                        <small v-if="v$.data.tahun_ak.$invalid" :class="{ 'p-error': v$.data.tahun_ak.$invalid ? !v$.data.tahun_ak.$anyError : null }">{{ checkValidasi('data', 'tahun_ak') }}</small>
                    </span>
                </div>
                <template #footer>
                    <Button label="Batal" @click="modalInput = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="register()" icon="" class="p-button-success" :disabled="!isValid" />
                </template>
            </Dialog>

            <!-- MODAL EDIT -->
            <Dialog header="Edit Data Tahun Akademik" v-model:visible="modalEdit" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true" @keydown.enter.prevent="simpanEdit()" @hide="v$.$reset()">
                <div class="col-12 lg:col-12 p-fluid">
                    <span class="p-float-label mt-4">
                        <InputText id="name" required="true" autofocus v-model.lazy="dataEdit.tahun_ak" @blur="v$.dataEdit.tahun_ak.$touch()" :class="{ 'p-invalid': v$.dataEdit.tahun_ak.$invalid ? !v$.dataEdit.tahun_ak.$anyError : null }" />
                        <label for="name">Nama Tahun Akademik</label>
                        <small v-if="v$.dataEdit.tahun_ak.$invalid" :class="{ 'p-error': v$.dataEdit.tahun_ak.$invalid ? !v$.dataEdit.tahun_ak.$anyError : null }">{{ checkValidasi('dataEdit', 'tahun_ak') }}</small>
                    </span>
                </div>
                <template #footer>
                    <Button label="Batal" @click="modalEdit = false" icon="" class="p-button-secondary" />
                    <Button label="Simpan" @click="simpanEdit()" icon="" class="p-button-success" :disabled="!isValidEdit" />
                </template>
            </Dialog>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
export default {
    setup() {
        return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    data() {
        return {
            tahun_akademik: [],
            total_tahun_akademik: null,
            field: ['tahun_ak'],
            loading1: true,
            modalInput: false,
            modalEdit: false,
            data: {
                tahun_ak: null,
            },
            dataEdit: {},
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            perPage: 10,
            perPages: [10, 25, 50, 100],
        };
    },
    validations() {
        return {
            data: {
                tahun_ak: {
                    required,
                    maxLength: maxLength(50),
                },
            },
            dataEdit: {
                tahun_ak: {
                    required,
                    maxLength: maxLength(50),
                },
            },
        };
    },
    computed: {
        formString() {
            return JSON.stringify(this.data);
        },
        isValid() {
            return !this.v$.data.$invalid;
        },
        isDirty() {
            return this.v$.data.$anyDirty;
        },
        isValidEdit() {
            return !this.v$.dataEdit.$invalid;
        },
        isDirtyEdit() {
            return this.v$.dataEdit.$anyDirty;
        },
    },
    mounted() {
        this.get_tahun_akademik();
        this.v$.$reset();
        // this.initFilters();
    },
    methods: {
        clearFilter1() {
            this.filters1 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        pages(x) {
            console.log(x);
        },
        initFilters1() {
            this.filters1 = {
                tahun_ak: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async get_tahun_akademik() {
            let vm = this;
            vm.loading1 = true;
            let tahun_akademik = await vm.$axiosbilling('tahunAkademik/listHalaman');
            // console.log(tahun_akademik.data.data, 'data tahun_akademik');
            vm.tahun_akademik = tahun_akademik.data.data.map((item, idx) => {
                item.dibuat = vm.$moment(item.createdAt).format('lll');
                item.diubah = vm.$moment(item.updatedAt).format('lll');
                return { ...item, no: idx + 1 };
            });
            vm.total_tahun_akademik = vm.tahun_akademik.length;
            vm.loading1 = false;
        },
        register() {
            let vm = this;
            if (vm.isValid && vm.isDirty) {
                // console.log(vm.data);
                vm.$axiosbilling
                    .post('tahunAkademik/register', vm.data)
                    .then((res) => {
                        // console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.data = {
                                    tahun_ak: null,
                                };
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mendaftarkan Tahun Akademik', life: 3000 });
                                this.get_tahun_akademik();
                            } else {
                                vm.modalInput = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalInput = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalInput = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        editData(data) {
            let vm = this;
            vm.dataEdit = data;
            vm.modalEdit = true;
            // console.log(vm.dataEdit, 'ini edit data', data);
        },
        simpanEdit() {
            let vm = this;
            let datanya = {};
            datanya.id = vm.dataEdit.id;
            datanya.tahun_ak = vm.dataEdit.tahun_ak;
            // console.log(datanya, 'ini dataEdit');
            if (vm.isValidEdit) {
                vm.$axiosbilling
                    .post('tahunAkademik/update', datanya)
                    .then((res) => {
                        // console.log(res.data.status);
                        if (res.data.status == 200) {
                            if (res.data.message == 'sukses') {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Mengupdate Data Tahun Akademik', life: 3000 });
                                this.get_tahun_akademik();
                            } else {
                                vm.modalEdit = false;
                                vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            }
                        } else {
                            vm.modalEdit = false;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        vm.modalEdit = false;
                        vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    });
            } else {
                vm.$toast.add({ severity: 'error', summary: 'Peringatan', detail: 'Masih terdapat isian data yang tidak valid', life: 3000 });
            }
        },
        simpanDelete(data) {
            let vm = this;
            vm.$axiosbilling
                .post('tahunAkademik/delete', { id: data.id })
                .then((res) => {
                    // console.log(res.data.status);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Menghapus Data Tahun Akademik', life: 3000 });
                            this.get_tahun_akademik();
                            this.$confirm.close();
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                            this.$confirm.close();
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: 'data sudah ada', life: 3000 });
                        this.$confirm.close();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    vm.$toast.add({ severity: 'error', summary: 'Konfirmasi', detail: 'Terjadi kesalahan pada server', life: 3000 });
                    this.$confirm.close();
                });
        },
        deleteData(data) {
            this.$confirm.require({
                message: 'Apakah anda yakin ingin menghapus ini?',
                header: 'Konfirmasi',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.simpanDelete(data);
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        checkValidasi(type, fieldName) {
            const field = this.v$[type][fieldName];
            let x = field.$errors[0].$validator;
            if (x == 'required') {
                return '* Data wajib diisi';
            } else if (x == 'email') {
                return '* Data harus berbentuk email';
            } else if (x == 'numeric') {
                return '* Data harus terdiri hanxa dari angka';
            } else if (x == 'minLength') {
                return `* Data minimal ${field.$errors[0].$params.min} digits`;
            } else if (x == 'maxLength') {
                return `* Data maksimal ${field.$errors[0].$params.max} digits`;
            } else if (x == 'alphaNum') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            } else if (x == 'official') {
                return `* Data tidak boleh mengandung spasi atau simbol`;
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}
</style>
